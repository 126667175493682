<template>  
  <v-container>
    <v-card elevation="0" class="d-flex mb-2 justify-center" style="border-radius: 24px 24px 8px 8px;">
      <v-card-title 
        class="pa-4 px-6 grey--text text--darken-3 font-weight-bold text-uppercase" 
        :style="($vuetify.breakpoint.xs ? 'font-size: 18px;' : 'font-size: 20px;')"
      >
        Ver corte del dia
      </v-card-title>
    </v-card>

    <v-alert
      cols="12" sm="12" md="12" 
      elevation="0"
      class="mb-2"
      border="left"
      colored-border
      color="blue-grey"
      :value="dismissCorteAlert"
      @input="() => {}"
    >
      <div class="d-flex grey--text text--darken-2 align-center justify-space-between">
        <!-- <v-icon class="mr-4" color="primary">mdi-information</v-icon> -->
        <div class="pl-2">
          La cantidad de los abonos cancelados no se tomará en cuenta para la sumatoria del total.
        </div>
        <v-btn @click="handleDismissCorteAlertOnClose" color="default" class="d-flex ml-4 font-weight-bold" style="color: #616161;">
          Quitar nota
        </v-btn>
      </div>
    </v-alert>

<!-- FILTROS REPORTE CORTE DEL DIA -->
    <v-card class="mt-5 mb-5" elevation="1">
      <v-row class="v-list-item">
        
        <v-col 
          cols="12" sm="6" md="4"
        > 
          <v-menu
            ref="dateRangeMenu"
            v-model="filter_datePagoRange.dateOpen"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <VTextField
                :value="filter_datePagoRange.formattedDate"
                :label="`Seleccionar Fecha`"
                :hint="
                  filter_datePagoRange.dateText
                    ? `${$dayjs(filter_datePagoRange.dateText).format('dddd, DD [de] MMMM [del] YYYY')}`
                    : filter_datePagoRange.dateText
                "
                @click:clear="handleClearFechaRango"
                class="mt-0"
                prepend-inner-icon="mdi-calendar"
                persistent-hint
                clearable
                readonly
                v-on="on"
              ></VTextField>
            </template>
            <v-date-picker 
              v-model="filter_datePagoRange.dateText" 
              @change="filter_datePagoRange.dateOpen = false"
              no-title 
              scrollable>
              <!-- <v-spacer></v-spacer>
              <v-btn text color="primary" @click="$refs.dateRangeMenu.save(handleDatePagoFilterRange(filter_datePagoRange.dateText))">OK</v-btn> -->
            </v-date-picker>
          </v-menu>
        </v-col> 

        <v-col 
          cols="12" sm="6" md="4"
        > 
          <v-autocomplete
            v-model="filter_cobrador"
            class="my-0"
            :label="`Cobrador`"
            :items="cobradoresSelectFilter"
            clearable
          ></v-autocomplete>
        </v-col> 

        <v-col 
          cols="12" sm="6" md="4"
        > 
          <v-autocomplete
            v-model="filter_cliente"
            class="my-0"
            :label="`Cliente`"
            :items="clientesSelectFilter"
            clearable
          ></v-autocomplete>
        </v-col> 

        <v-col 
          cols="12" sm="6" md="4"
        > 
          <v-select
            v-model="filter_turno"
            class="my-0"
            :label="`Turno`"
            :items="turnoSelectFilter"
            clearable
          ></v-select>
        </v-col>

        <v-col 
          cols="12" sm="6" md="4"
        > 
          <v-autocomplete
            v-model="filter_formaPago"
            class="my-0"
            :label="`Forma de pago`"
            :items="formaPagoSelectFilter"
            clearable
          ></v-autocomplete>
        </v-col>

        <v-col 
          cols="12" sm="6" md="4"
        > 
          <v-checkbox
            v-model="filter_domiciliado"
            color="primary"
            class="my-0"
            :style="{ maxWidth: 'max-content' }"
            :label="`Domiciliados`"
            :hint="''"
            persistent-hint
          ></v-checkbox>
        </v-col>

      </v-row>
    </v-card>
<!-- FILTROS REPORTE CORTE DEL DIA -->

    <DataTable
      id="reporte-corte-table"
      ref="dtable_rep_corte"
      :headers="headers"
      :title="`Total: ${formatCurrency(total_fecha_seleccionada)}`"
      :subtitle="''"
      :rightText="`${
        reporteCorteTable.items.length 
          ? `${reporteCorteTable.items.length} ${reporteCorteTable.items.length > 1 ? 'registros encontrados' : 'registro encontrado'}`
          : 'No hay datos disponibles'
      }`"
      :items="reporteCorteTable.items"
      :loading="tableLoading"
      :searchEnabled="false"
      :showToolbar="true"
      :sortBy="['id']"
      :sortDesc="[false, true]"
      :showSelect="true"
      :showExportExcel="true"
      @onRowClick="handleCorteRow"
    >
      <template v-slot:showExportExcel="{ rowValues }">
        <a id="exportarExcel">
          <download-excel
            :header="`
              Reporte corte del dia: ${$dayjs(filter_datePagoRange.dateText).tz().format('DD/MM/YYYY')}
              | Total: 
              ${formatCurrency(rowValues.filter(item => item.abono_estatus).reduce((acc, val) => {
                return acc + +val.cantidad;
              }, 0))}
            `"
            :name="`Reporte_Corte_${$dayjs().tz().format('DD_MM_YYYY-hh_mm_ss_a')}.xls`"
            :export-fields="reporte_corte_export_fields"
            :data="rowValues"
            type="xls"
            :default-value="''"
          >
            Exportar a Excel
          </download-excel>
        </a>
      </template>
    </DataTable>

  </v-container>
</template>

<script>
import DataTable from "@/components/ui/DataTable";
import useDataTable from "@/composables/useDataTable";
import { APP_DEBUG_MODE as APP_DEBUG, TURNO_PAGO_SELECT, FORMA_PAGO_SELECT } from "@/utils/Constants";
import { formatCurrency, setStorageItem, getStorageItem } from "@/utils/Functions";
import { state, actions, getters, STORE_NAMESPACES } from "@/store";
import { getCurrentInstance, ref, watch, computed, onBeforeUnmount, onUpdated } from "vue";
import useDatepicker from "@/composables/useDatepicker";
import useDatepickerRange from "@/composables/useDatepickerRange";

export default {
  components: {
    DataTable,
  },
  setup(props) {
    const vm = getCurrentInstance();
    const { APP, USERS, CLIENTES, ABONOS } = STORE_NAMESPACES;
        
    const IS_DEV_USER = getters[APP].getIsDevUser.value;
    const APP_DEBUG_MODE = APP_DEBUG || IS_DEV_USER;

    onUpdated(() => { 
      if (vm?.proxy?.$refs?.dtable_rep_corte?._data?.rowValues?.length) {
        vm.proxy.$refs.dtable_rep_corte._data.rowValues = [];
      }
    });

    onBeforeUnmount(() => {
      APP_DEBUG_MODE && console.log("[Reporte_Corte]: Abonos unmount => SET_DEFAULT()");

      actions[ABONOS].SET_DEFAULT();
    });

    const reporte_corte_export_fields = {
      "Póliza": "plan_poliza",
      "Núm. cliente": "num_cliente",
      "Nombre del cliente": "nombre_completo_cliente",
      "Cobrador asociado": "nombre_completo_cobrador",
      "Cantidad": {
        field: "cantidad",
        callback: (value) => {
          return `${formatCurrency(value)}`;
        }
      },
      "Fecha": {
        field: "fecha_abono",
        callback: (value) => {
          return `${vm.proxy.$root.$dayjs(value).tz().format("DD/MM/YYYY")}`;
        }
      },
      "Hora": {
        field: "fecha_abono",
        callback: (value) => {
          return `${vm.proxy.$root.$dayjs(value).tz().format("hh:mm:ss a")}`;
        }
      },
      "Estatus": {
        field: "abono_estatus",
        callback: (value) => {
          return `${value ? "Ok" : "Cancelado"}`;
        }
      },
    }

    // const forma_pago_header = ref("Trimestre");
    const headers = computed(() => {
      return [
        { text: "Id", value: "id", sortable: false },
        { text: "Póliza", value: "planes_clientes.eliminado" },
        { text: "Núm. cliente", value: "clientes_corte.num_cliente" },
        { text: "Nombre del cliente", value: "clientes.nombre_completo" },
        { text: "Cobrador asociado", value: "users.nombre_completo" },
        { text: "Abono", value: "abono_activo" },
        { text: "Hora", value: "custom_hora_abono" },
      ]
    });

    const tableLoading = getters[APP].getLoading;
    const dataSource   = getters[ABONOS].getAllByDate;
    const reporteCorteTable = useDataTable(dataSource, {});
    const dismissCorteAlert = ref(!getStorageItem("dismiss-corte-alert"));

    const filter_datePagoRange = useDatepicker(0, "", true);
    const filter_cobrador = ref("");
    const filter_cliente = ref("");
    const filter_turno = ref("");
    const filter_formaPago = ref("");
    const filter_domiciliado = ref(false);

    actions[ABONOS].FETCH_ALL_BY_DATE(
      filter_datePagoRange.dateText
    );

    const turnoSelectFilter = TURNO_PAGO_SELECT;
    const formaPagoSelectFilter = FORMA_PAGO_SELECT;
    
    const cobradores_filter = getters[USERS].getAll;
    const cobradoresSelectFilter = computed(() => {
      return cobradores_filter.value
        .map(user => ({ text: `${user.nombre} ${user.apellidos || ''}`, value: user.id }));
    });

    const clientes_filter = getters[CLIENTES].getAll;
    const clientesSelectFilter = computed(() => {
      const clientes = clientes_filter.value
        .map(cliente => ({ text: `${cliente.num_cliente} - ${cliente.nombre} ${cliente.apellidos || ''}`, value: cliente.id }));

      clientes.push({ text: `Ver eliminados`, value: -1 });
      return clientes;
    });

    const total_fecha_seleccionada = computed(() => 
      reporteCorteTable.items.reduce((accumulator, currentValue) => {
        const cantidad_abono_no_cancelado = currentValue.abono_activo ? +currentValue.cantidad : 0;

        return +accumulator + cantidad_abono_no_cancelado;
      }, 0)
    );

    // Filtros
    reporteCorteTable.items = computed(() => {
      return dataSource.value.filter(item => {
        return (!filter_cobrador.value || item.users.id == filter_cobrador.value)
          && (!filter_cliente.value || item.clientes.id == filter_cliente.value || item.clientes.eliminado == filter_cliente.value)
          && (!filter_turno.value || item.plan_turno_pago == filter_turno.value)
          && (!filter_formaPago.value || item.plan_forma_pago == filter_formaPago.value)
          && (!filter_domiciliado.value || item.domiciliado == filter_domiciliado.value)
      }) || []
    });

    function handleClearFechaRango() {
      filter_datePagoRange.dateText = null;
      actions[ABONOS].FETCH_ALL_BY_DATE(
        filter_datePagoRange.dateText
      );
    }

    function handleDismissCorteAlertOnClose() {
      setStorageItem("dismiss-corte-alert", true);
      dismissCorteAlert.value = !dismissCorteAlert.value;
    }

    watch(() => filter_datePagoRange.dateOpen, (value) => {
      if (value == false) {
        actions[ABONOS].FETCH_ALL_BY_DATE(
          filter_datePagoRange.dateText
        );
      }
    });
    
    function handleCorteRow(contratoId) {

    }

    return {
      /** IMPORTS */
      formatCurrency,
      /** IMPORTS */

      reporte_corte_export_fields,
      headers,

      reporteCorteTable,
      dismissCorteAlert,
      
      filter_datePagoRange,
      filter_cobrador,
      filter_cliente,
      filter_turno,
      filter_formaPago,
      filter_domiciliado,

      handleCorteRow,
      handleClearFechaRango,
      handleDismissCorteAlertOnClose,
      cobradoresSelectFilter,
      clientesSelectFilter,
      turnoSelectFilter,
      formaPagoSelectFilter,

      tableLoading,
      total_fecha_seleccionada,
    };
  }
};
</script>
